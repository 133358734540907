import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Image from '../components/image'
import { graphql } from 'gatsby'

import Link from '../components/link'
import Modal from '../components/modal'
import PageLinks from '../components/page-links'
import Icon from '../components/icon'
import styles from './membership.module.css'
import SEO from '../components/seo'

const slugify = (text) => {
  return text.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}

const MembershipProgrammes = ({ programmes, title }) => (
  <div className={`${styles.membershipProgrammes}`}>
    <h2 className={styles.title}>{title}</h2>
    <h1 className={styles.membershipTitle}>{programmes.title}</h1>
    <div
      className={`${styles.membershipBody} mx-auto`}
      dangerouslySetInnerHTML={{ __html: programmes.bodyHtml }}
    />
  </div>
)

export const MembershipType = ({ type }) => {
  const {
    title,
    shortDescription,
    descriptionHtml,
    linkURL,
    price,
    coverImage,
    conditions,
    benefits,
  } = type.frontmatter

  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
    <SEO 
      title={"The Projector Membership Programmes for Exclusive Perks"}
      description={"Membership programmes for some extra perks at The Projector like free tickets, discounts, special giveaways and exclusive invites to events and screenings."}
    />
      <div className={styles.typeWrapper}>
        <div
          className={styles.typeModalImageWrapper}
          onClick={() => setIsModalOpen(true)}
        >
          {coverImage && coverImage.childImageSharp ? (
            <Image
              className={`${styles.typeImage}`}
              fluid={coverImage.childImageSharp.fluid}
            />
          ) : null}
        </div>
        <div className={`${styles.typeDetailsContainer} text-center`}>
          <h2 className={styles.typeHeader}>The Projector</h2>
          <h1 className={styles.typeTitle}>{title}</h1>
          <p className={styles.typeDescription}>{shortDescription}</p>
          <button className={`${styles.typeButton} btn-primary`}
            onClick={event => {
              typeof window !== "undefined" && window.gtag && window.gtag("event", slugify(title) + `-button-click`, {
                event_category: `Join for $${price}`,
                event_label: 'Projector',
                value: price,
              })
              typeof window !== "undefined" && window.open(linkURL, '_blank')
            }}>
            Join for ${price}
          </button>
        </div>
      </div>
      <Modal
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        className={styles.membershipModal}
      >
        <div className={styles.modalContainer}>
          <div
            className={`${styles.typeModalInfo} flex flex-col lg:flex-row text-left`}
          >
            {coverImage && coverImage.childImageSharp ? (
              <Image
                className={`${styles.typeModalImage}`}
                fluid={coverImage.childImageSharp.fluid}
              />
            ) : null}
            <div className={`${styles.typeModalContent} flex-1`}>
              <h2 className={styles.typeHeader}>The Projector</h2>
              <h1 className={styles.typeModalTitle}>{title}</h1>
              <div
                className={`${styles.typeModalDescription}`}
                dangerouslySetInnerHTML={{ __html: descriptionHtml }}
              />
              <button className={`btn-primary ` + styles.typeModalLink} onClick={event => {
                typeof window !== "undefined" && window.gtag && window.gtag("event", slugify(title) + `-button-click`, {
                  event_category: `Join for $${price}`,
                  event_label: 'Projector',
                  value: price,
                })
                typeof window !== "undefined" && window.open(linkURL, '_blank')
              }}>Join for ${price}</button>
            </div>
          </div>
          <div className={`${styles.typeBenefits}`}>
            <div className={`${styles.typeModalHeader} w-full text-center`}>
              Enjoy These Benefits
            </div>
            <div
              className={`${styles.typeBenefitsList} flex flex-wrap mb-2 lg:mt-1`}
            >
              {(benefits || []).map((b, i) => (
                <div
                  key={i}
                  className={`${styles.typeBenefit} w-full text-center mx-auto p-4 md:w-1/2 lg:w-1/4`}
                >
                  <div className={`${styles.typeBenefitImage}`}>
                    <Icon icon={b.icon} />
                  </div>
                  <p className={styles.typeBenefitDescription}>
                    {b.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className={`${styles.typeConditions}`}>
            <div className={`${styles.typeModalHeader} w-full text-center`}>
              Conditions
            </div>
            <div
              className={`${styles.typeConditionsList} flex flex-wrap mb-2 lg:mt-1`}
            >
              {(conditions || []).map((c, i) => (
                <div
                  key={i}
                  className={`${styles.typeCondition} w-full text-center mx-auto p-4 lg:w-1/3`}
                >
                  <p className={styles.typeConditionDescription}>{c}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

const MembershipTypes = ({ types }) =>
  types && types.length ? (
    <div className={`${styles.typesWrapper} flex flex-wrap mb-4`}>
      {types
        .sort((a, b) => a.frontmatter.order - b.frontmatter.order)
        .map((t, i) => (
          <div
            key={i}
            className={`${styles.typeContainer} w-full mx-auto p-2 md:w-1/2 lg:w-1/3`}
          >
            <MembershipType type={t} />
          </div>
        ))}
    </div>
  ) : (
      ''
    )

const MembershipPerkCards = ({ perk }) => {
  const {
    conditions,
    descriptionHtml,
    name,
    externalLink,
    validity,
    cardImage,
    modalImage,
  } = perk

  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <div className={`${styles.perkWrapper}`}>
        <div
          className={`${styles.typeModalImageWrapper} ${styles.typeImage}`}
          onClick={() => setIsModalOpen(true)}
        >
          {cardImage && cardImage.childImageSharp ? (
            <Image
              className={`${styles.perkImage}`}
              fluid={cardImage.childImageSharp.fluid}
            />
          ) : null}
          <div className={styles.perkImageShadow} />
          <div className={styles.perkName}>{name}</div>
        </div>
      </div>
      <Modal
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        className={styles.membershipModal}
      >
        <div className="w-full text-left flex flex-col lg:flex-row lg:h-full lg:overflow-hidden ">
          {modalImage && modalImage.childImageSharp ? (
            <Image
              className={`${styles.perkModalImage}`}
              fluid={modalImage.childImageSharp.fluid}
            />
          ) : null}
          <div className={styles.perkModalContent}>
            <h1 className={styles.perkModalName}>{name}</h1>
            <p className={styles.perkModalValidity}>Validity: {validity}</p>
            <div
              className={`${styles.perkModalDescription}`}
              dangerouslySetInnerHTML={{ __html: descriptionHtml }}
            />
            {externalLink && externalLink.linkURL ? (
              <a
                className={`${styles.perkModalCta} btn-primary`}
                href={externalLink.linkURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                {externalLink.callToAction || 'Find Out More'}
              </a>
            ) : (
                ''
              )}
            <div className={`${styles.perkModalHeader}`}>Conditions</div>
            <div className={`${styles.perkConditionsList}`}>
              {(conditions || []).map((c, i) => (
                <div key={i} className={`${styles.perkCondition}`}>
                  {c}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

const MembershipSpecial = ({ special }) => (
  <>
    <div
      className={`${styles.membershipSpecial} mt-20 lg:mt-32 md:items-start md:text-left md:mx-auto`}
    >
      <h2 className={styles.title}>{special.title}</h2>
      <div className="flex flex-col md:flex-row md:justify-between">
        <h1 className={`${styles.membershipSpecialTitle} md:w-2/5`}>
          {special.subtitle}
        </h1>
        <div
          className={`${styles.membershipBody} mx-auto md:mr-0 md:ml-16`}
          dangerouslySetInnerHTML={{ __html: special.descriptionHtml }}
        />
      </div>
    </div>
    {special.membershipPerks && special.membershipPerks.length ? (
      <div
        className={`${styles.membershipPerks} w-full flex flex-wrap justify-center my-20`}
      >
        {special.membershipPerks.map((p, i) => (
          <div className="w-full mx-0 p-2 md:w-1/3 lg:w-1/4" key={i}>
            <MembershipPerkCards perk={p} />
          </div>
        ))}
      </div>
    ) : (
        <div
          className={`${styles.membershipPerks} w-full flex flex-wrap justify-center mt-20`}
        />
      )}
  </>
)

export const MembershipTemplate = ({ page }) => {
  const {
    membershipProgrammes,
    memberSpecial,
    title,
    pageLinks,
  } = page.frontmatter
  const { membershipTypes } = page.fields
  return (
    <div className="container flex flex-col items-center text-center py-5 lg:py-20">
      <MembershipProgrammes programmes={membershipProgrammes} title={title} />
      <MembershipTypes types={membershipTypes} />
      <MembershipSpecial special={memberSpecial} />
      <div className="w-full mt-24">
        <PageLinks links={pageLinks} />
      </div>
    </div>
  )
}

const MembershipPage = ({ data }) => {
  return <MembershipTemplate {...data} />
}

MembershipPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object,
  }),
}

export default MembershipPage

export const pageQuery = graphql`
  query MembershipPage {
    page: markdownRemark(fields: { slug: { eq: "/membership/" } }) {
      frontmatter {
        title
        membershipProgrammes {
          bodyHtml
          title
        }
        memberSpecial {
          subtitle
          title
          descriptionHtml
          membershipPerks {
            conditions
            descriptionHtml
            name
            externalLink {
              callToAction
              linkURL
            }
            validity
            cardImage: image {
              childImageSharp {
                fluid(maxWidth: 280, maxHeight: 360, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            modalImage: image {
              childImageSharp {
                fluid(maxWidth: 455, maxHeight: 625, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        ...PageLinks
      }
      fields {
        membershipTypes {
          frontmatter {
            title
            order
            shortDescription
            descriptionHtml
            price
            linkURL
            coverImage {
              childImageSharp {
                fluid(
                  maxWidth: 390
                  maxHeight: 330
                  quality: 90
                  fit: CONTAIN
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            conditions
            benefits {
              icon {
                childImageSharp {
                  fluid(
                    maxWidth: 390
                    maxHeight: 330
                    quality: 90
                    fit: CONTAIN
                  ) {
                    ...GatsbyImageSharpFluid
                  }
                }
                extension
                publicURL
                name
              }
              description
            }
          }
        }
      }
    }
  }
`
